import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import CloseButton from '../../../../components/CloseButton';
import Input from '../../../../components/Form/Input';
import Cluster from '../components/Cluster';
import useSite from '../../../../common/hooks/useSite';
import updateSite from '../../../../common/db/sites';
import ConfirmDeactivation from '../components/ConfirmDeactivation';
import SiteStatusIndicator from '../components/SiteStatusIndicator';

interface SiteDetailsProps {
  selectedSiteId: string;
  setSelectedSiteId: (id: string | undefined) => void;
}

interface SiteDetailsForm {
  name: string;
}

const SiteDetails: React.FC<SiteDetailsProps> = ({ selectedSiteId, setSelectedSiteId }) => {
  const { error, loading, site } = useSite(selectedSiteId);
  const { register, reset, handleSubmit } = useForm<SiteDetailsForm>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  useEffect(() => {
    if (site) {
      reset({
        name: site.name,
      });
    }
  }, [site]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const onSubmit = (data: SiteDetailsForm) => {
    updateSite(selectedSiteId, data);
  };

  const handleOnClose = () => setSelectedSiteId(undefined);

  const handleOnDeactivate = () => {
    setShowConfirmation(true);
  };

  const onDeactivate = () => {
    updateSite(selectedSiteId, { active: false });
    handleOnClose();
  };

  return (
    <>
      <ConfirmDeactivation
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={onDeactivate}
      />
      <Card className="w-1/2 md:w-full my-4 p-4 flex flex-col overflow-y-auto no-scrollbar">
        <div className="flex justify-between mb-4">
          {site?.active && site.deletionRequested ? (
            <Button type="button" color="accent2" onClick={handleOnDeactivate}>
              <FaTrash />
            </Button>
          ) : (
            <div />
          )}
          <CloseButton onClick={handleOnClose} className="max-w-min self-end" />
        </div>
        {!site && !loading && !error ? (
          <div className="text-center flex justify-between">Select a site to view details</div>
        ) : (
          <>
            <div className="flex flex-col">
              <SiteStatusIndicator
                active={site?.active}
                deletionRequested={site?.deletionRequested}
              />
              <h1 className="my-4 font-thin font-nunito">{site?.id}</h1>

              <form className="w-full flex justify-between flex-wrap mt-3">
                <Input
                  type="text"
                  name="name"
                  register={register}
                  className="flex-1/2 font-bold font-outfit text-2xl mb-2"
                />
              </form>

              <h2 className="font-bold mt-3">Site owner</h2>
              <p>
                {site?.siteOwner.name}{' '}
                <span className="font-thin text-sm">({site?.siteOwner.id})</span>
              </p>
              <p>HasWhatsApp: {site?.siteOwner.hasWhatsApp ? 'true' : 'false'}</p>

              <h2 className="font-bold mt-3">Primary TAE</h2>
              <p>
                {site?.primaryTAE.name}{' '}
                <span className="font-thin text-sm">({site?.primaryTAE?.id})</span>
              </p>

              <h2 className="font-bold mt-3">FSM</h2>
              <p>
                {site?.fsm?.name} <span className="font-thin text-sm">({site?.fsm?.id})</span>{' '}
              </p>

              <h2 className="font-bold mt-3">SOP</h2>
              <p>
                {site?.sop.name} <span className="font-thin text-sm">({site?.sop?.id})</span>
              </p>

              <h2 className="font-bold mt-3">User IDs</h2>
              <ul className="list-inside list-disc">
                {site?.userIds.map(userId => (
                  <li key={userId}>{userId}</li>
                ))}

                <h2 className="font-bold mt-3">Other</h2>
                <p>Status: {site?.status}</p>
                {site?.cluster && <Cluster cluster={site.cluster} />}
              </ul>
            </div>
            <Button
              color="accent1"
              className="my-2 disabled:opacity-50"
              text="Save"
              disabled
              onClick={handleSubmit(onSubmit)}
            />
            <Button
              color="accent2"
              className="my-2 disabled:opacity-50"
              text="Deactivate"
              disabled
              onClick={handleOnClose}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default SiteDetails;
