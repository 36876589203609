import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { List } from '../../../../components/List';
import useSites from '../../../../common/hooks/useSites';
import SiteItem from '../components/SiteItem';

interface SitesListProps {
  searchText?: string;
  setSelectedSiteId: (id: string) => void;
  status: 'active' | 'inactive' | 'markedForDeletion' | null;
}

const SitesList: React.FC<SitesListProps> = ({ searchText, setSelectedSiteId, status }) => {
  const { error, hasMore, load, loading, sites } = useSites({
    searchText,
    status,
    emptySearch: true,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-auto no-scrollbar px-4 w-full md:w-full"
    >
      {sites &&
        sites.map(site => <SiteItem key={site.id} site={site} onClick={setSelectedSiteId} />)}
    </List>
  );
};

export default SitesList;
