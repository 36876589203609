import { useEffect } from 'react';
import { toast } from 'react-toastify';
import useMissingDeliveries from '../../../../common/hooks/useMissingDeliveries';
import { MissingDeliveryStatus } from '../../../../common/types/MissingDelivery';
import { List } from '../../../../components/List';
import MissingDeliveryItem from '../components/MissingDeliveryItem';

interface MissingDeliveriesListProps {
  status: MissingDeliveryStatus | undefined;
  searchText?: string;
  setMissingDeliveryId: (id: string) => void;
  selectedMissingDeliveryId: string | undefined;
}

const MissingDeliveriesList: React.FC<MissingDeliveriesListProps> = ({
  searchText,
  status,
  setMissingDeliveryId,
  selectedMissingDeliveryId,
}) => {
  const { error, hasMore, load, loading, missingDeliveries } = useMissingDeliveries({
    searchText,
    status,
    emptySearch: true,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-scroll no-scrollbar px-4 w-full md:w-full"
    >
      {missingDeliveries.map(missingDelivery => (
        <MissingDeliveryItem
          key={missingDelivery.id}
          missingDelivery={missingDelivery}
          onClick={setMissingDeliveryId}
          selected={selectedMissingDeliveryId === missingDelivery.id}
        />
      ))}
    </List>
  );
};

export default MissingDeliveriesList;
