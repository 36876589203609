import clsx from 'clsx';
import { MissingDeliveryStatus } from '../../../../common/types/MissingDelivery';
import MissingDeliveriesList from '../blocks/MissingDeliveriesList';

interface LeftCenterProps {
  status: MissingDeliveryStatus | undefined;
  searchText?: string;
  setMissingDeliveryId: (id: string) => void;
  className?: string;
  selectedMissingDeliveryId?: string;
}

const LeftCenter: React.FC<LeftCenterProps> = ({
  className,
  searchText,
  status,
  setMissingDeliveryId,
  selectedMissingDeliveryId,
}) => {
  const style = clsx(
    className,
    'flex flex-col items-center',
    selectedMissingDeliveryId && 'w-1/2',
    !selectedMissingDeliveryId && 'w-full'
  );

  return (
    <div className={style}>
      <MissingDeliveriesList
        searchText={searchText}
        status={status}
        setMissingDeliveryId={setMissingDeliveryId}
        selectedMissingDeliveryId={selectedMissingDeliveryId}
      />
    </div>
  );
};

export default LeftCenter;
