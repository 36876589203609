import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { List } from '../../../../components/List';
import useOutlets from '../../../../common/hooks/useOutlets';
import OutletItem from '../components/OutletItem';

interface OutletsListProps {
  searchText?: string;
  setSelectedOutletId: (id: string) => void;
  status: 'active' | 'inactive' | 'markedForDeletion' | null;
}

const OutletsList: React.FC<OutletsListProps> = ({ searchText, setSelectedOutletId, status }) => {
  const { error, hasMore, load, loading, outlets } = useOutlets({
    searchText,
    status,
    emptySearch: true,
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <List
      load={load}
      hasMore={hasMore}
      loading={loading}
      className="overflow-y-auto no-scrollbar px-4 w-full md:w-full"
    >
      {outlets &&
        outlets.map(outlet => (
          <OutletItem key={outlet.id} outlet={outlet} onClick={setSelectedOutletId} />
        ))}
    </List>
  );
};

export default OutletsList;
