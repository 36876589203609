import {
  FaCrosshairs,
  FaHammer,
  FaMoneyBill,
  FaHome,
  FaRegChartBar,
  FaStore,
  FaUser,
  FaLayerGroup,
  FaTruck,
} from 'react-icons/fa';
import { useCheckAccess } from '../../../../common/hooks/auth';
import StyledNavLink from '../components/StyledNavLink';

type Props = {
  open: boolean;
};

const Menu: React.FC<Props> = ({ open }) => {
  const checkAccess = useCheckAccess();

  return (
    <>
      {checkAccess(['admin', 'analytics']) && (
        <StyledNavLink to="/home">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaHome />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Home</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/users">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaUser />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Users</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/deliveries">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaTruck />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Deliveries</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/outlets">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaStore />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Outlets</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/sites">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaHammer />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Sites</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/targets">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaCrosshairs />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Targets</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['admin']) && (
        <StyledNavLink to="/groups">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaLayerGroup />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Groups</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['analytics']) && (
        <StyledNavLink to="/dashboard">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaRegChartBar />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Dashboard</span>
          </li>
        </StyledNavLink>
      )}
      {checkAccess(['costs']) && (
        <StyledNavLink to="/costsOverview">
          <li
            className={`flex rounded-md cursor-pointer items-center gap-x-4 ${open ? 'w-40' : ''}`}
          >
            <FaMoneyBill />
            <span className={`${!open && 'hidden'} origin-left duration-200`}>Costs overview</span>
          </li>
        </StyledNavLink>
      )}
    </>
  );
};

export default Menu;
