/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { debounce } from '../../../../common/utils';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { MissingDeliveryStatus } from '../../../../common/types/MissingDelivery';

interface SearchBarProps {
  onSearchChange: (searchText: string) => void;
  onStatusChange: (status: MissingDeliveryStatus) => void;
}

interface StatusOption {
  label: string;
  value: MissingDeliveryStatus;
}

interface SearchBarForm {
  searchText: string;
  status: StatusOption;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchChange, onStatusChange }) => {
  const { register, getValues, control, watch, reset } = useForm<SearchBarForm>({
    defaultValues: {
      searchText: '',
      status: { label: 'Pending', value: 'pending' },
    },
  });

  const watchStatus = watch('status');

  useEffect(() => {
    if (watchStatus) {
      onStatusChange(watchStatus.value);
    }
  }, [watchStatus, onStatusChange]);

  const statusOptions: StatusOption[] = useMemo(
    () => [
      { label: 'Pending', value: 'pending' },
      { label: 'Confirmed', value: 'confirmed' },
      { label: 'Rejected', value: 'rejected' },
      { label: 'Exists', value: 'exists' },
    ],
    []
  );

  useEffect(() => {
    reset({
      searchText: '',
      status: statusOptions[0],
    });
  }, [reset, statusOptions]);

  const searchTextOptions: RegisterOptions = {
    onChange: debounce(() => {
      const searchText = getValues('searchText');
      if (searchText.length > 2) {
        onSearchChange(searchText);
      }
    }, 500),
  };

  const selectStyles: StylesConfig = {
    valueContainer: provided => ({
      ...provided,
      flexWrap: 'nowrap',
    }),
    input: provided => ({
      ...provided,
      overflow: 'hidden',
    }),
  };

  return (
    <div className="w-full p-2 z-10 top-1 border-b border-gray-200">
      <form onSubmit={event => event.preventDefault()} className="flex">
        <Input
          name="searchText"
          placeholder="Search delivery..."
          register={register}
          options={searchTextOptions}
          className="flex-3/4 mr-2 px-2 bg-transparent"
        />
        <Select
          name="status"
          options={statusOptions}
          control={control}
          placeholder="Select status..."
          rules={undefined}
          className="flex-1/4 ml-2"
          styles={selectStyles}
        />
      </form>
    </div>
  );
};

export default SearchBar;
