import useMissingDelivery from '../../../../common/hooks/useMissingDelivery';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

interface MissingDeliveryDetailsProps {
  selectedMissingDeliveryId: string | undefined;
  setSelectedMissingDeliveryId: (id: string | undefined) => void;
}

const MissingDeliveryDetails: React.FC<MissingDeliveryDetailsProps> = ({
  selectedMissingDeliveryId,
}) => {
  const { missingDelivery, confirm, reject } = useMissingDelivery(selectedMissingDeliveryId);

  const onConfirm = () => {
    confirm();
  };

  const onReject = () => {
    reject();
  };

  return (
    <Card className="w-full md:w-full shadow-none rounded-xl">
      <p>
        LOGON Order Number: <span className="font-bold">{missingDelivery?.LOGONOrderNumber}</span>
      </p>
      <div className="flex flex-col">
        <p>{missingDelivery?.distributor.name}</p>
        <p>{missingDelivery?.distributor.phone}</p>
        <p>
          ShipTo/SoldTo: <span>{missingDelivery?.distributor.sapId}</span>
        </p>
      </div>
      {missingDelivery?.status === 'pending' && (
        <div className="flex w-full gap-2 mt-4">
          <Button onClick={onConfirm} color="accent1">
            Confirm
          </Button>
          <Button onClick={onReject} color="accent2">
            Reject
          </Button>
        </div>
      )}
    </Card>
  );
};

export default MissingDeliveryDetails;
