import dayjs from 'dayjs';
import { AnalyticsType, FilterOptions } from '../../../types/Analytics';
import { checkIfDataIsInFilter, convertTimestampToDate } from '../helperFunctions';

type ChannelData = {
  channel: string;
  volume: number;
  numberOfOutlets: number;
  color: string;
};

type OutletData = {
  volume: number;
  channel: string;
};

const getChannelColor = (channel?: string) => {
  switch (channel) {
    // use calm colors in blue and grey tones

    case 'blockmaker_retailer':
      return '#f5cb42'; // Example color for Blockmaker Retailer
    case 'neighborhood_shop':
      return '#4287f5'; // Example color for Neighborhood Shop
    case 'container':
      return '#f58442';
    case 'blockmaker':
      return '#8a42f5';
    case 'tile_seller':
      return '#FF6347';
    case 'palleter':
      return '#3ec41f';
    default:
      return '#D3D3D3';
  }
};

const computeChannels = (analyticsData: AnalyticsType[], filter: FilterOptions) => {
  const dataPreviousPeriod: ChannelData[] = [];
  const dataCurrentPeriod: ChannelData[] = [];

  const currentPeriodStartDate = filter.dateRange1.startDate;
  const currentPeriodEndDate = dayjs(filter.dateRange1.endDate).add(1, 'day').toDate();
  const previousPeriodStartDate = filter.dateRange2.startDate;
  const previousPeriodEndDate = dayjs(filter.dateRange2.endDate).add(1, 'day').toDate();

  const outletsMap: Map<string, OutletData> = new Map();
  const outletsMapPrevious: Map<string, OutletData> = new Map();

  analyticsData.forEach(data => {
    const isInFilter = checkIfDataIsInFilter(data, filter);

    if (isInFilter) {
      const date = convertTimestampToDate(data.date);

      if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
        if (Array.isArray(data.outlets)) {
          data.outlets.forEach(outlet => {
            const tempOutlet = outletsMap.get(outlet.id);
            let newVolume = 0;
            if (tempOutlet) {
              const currentVolume = tempOutlet.volume;
              newVolume = outlet.volume + currentVolume;
            } else {
              newVolume = outlet.volume;
            }

            outletsMap.set(outlet.id, {
              volume: newVolume,
              channel: outlet.channel,
            });
          });
        }
      } else if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
        if (Array.isArray(data.outlets)) {
          data.outlets?.forEach(outlet => {
            const tempOutlet = outletsMapPrevious.get(outlet.id);
            let newVolume = 0;
            if (tempOutlet) {
              const currentVolume = tempOutlet.volume;
              newVolume = outlet.volume + currentVolume;
            } else {
              newVolume = outlet.volume;
            }

            outletsMapPrevious.set(outlet.id, {
              volume: newVolume,
              channel: outlet.channel,
            });
          });
        }
      }
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of outletsMap) {
    // group by channel
    const { channel } = value;
    const tempChannel = dataCurrentPeriod.find(item => item.channel === channel);
    if (tempChannel) {
      tempChannel.volume += value.volume;
      tempChannel.numberOfOutlets++;
    } else {
      dataCurrentPeriod.push({
        channel,
        volume: value.volume,
        numberOfOutlets: 1,
        color: getChannelColor(channel),
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of outletsMapPrevious) {
    // group by channel
    const { channel } = value;
    const tempChannel = dataPreviousPeriod.find(item => item.channel === channel);
    if (tempChannel) {
      tempChannel.volume += value.volume;
      tempChannel.numberOfOutlets++;
    } else {
      dataPreviousPeriod.push({
        channel,
        volume: value.volume,
        numberOfOutlets: 1,
        color: getChannelColor(),
      });
    }
  }

  // sort by number of outlets
  dataCurrentPeriod.sort((a, b) => b.numberOfOutlets - a.numberOfOutlets);
  dataPreviousPeriod.sort((a, b) => b.numberOfOutlets - a.numberOfOutlets);

  return [dataCurrentPeriod, dataPreviousPeriod];
};

export default computeChannels;
