import clsx from 'clsx';
import { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import CsvDownloader from 'react-csv-downloader';
import useStore from '../../common/hooks/useStore';
import { AnalyticsType, FilterOptions, PerformanceData } from '../../common/types/Analytics';
import { computeCement, renderLabelRoundedOnTwoDecimalPlaces } from '../../common/utils/analytics';
import Header from './Header';
import csvLogo from '../../common/assets/images/export-csv.png';

type CementChartProps = {
  data: AnalyticsType[];
  filter: FilterOptions;
};

const CementChart: React.FC<CementChartProps> = ({ data, filter }: CementChartProps) => {
  const [state] = useStore();
  const [
    headerDataCurrentPeriod,
    chartDataCurrentPeriod,
    headerDataPreviousPeriod,
    chartDataPreviousPeriod,
    performance,
  ]: any = useMemo(() => computeCement(data, filter), [state.recompute]);

  const startDate = filter.dateRange1.startDate.toLocaleDateString();
  const endDate = filter.dateRange1.endDate.toLocaleDateString();
  const startDateLastPeriod = filter.dateRange2.startDate.toLocaleDateString();
  const endDateLastPeriod = filter.dateRange2.endDate.toLocaleDateString();

  const downloadCSV = (dataToDownload: any) => {
    const csvData = dataToDownload.map((item: any) => ({
      name: item.name,
      classic: item.fulfilled.classic,
      supaset: item.fulfilled.supaset,
      watershield: item.fulfilled.watershield,
      total: item.fulfilled.classic + item.fulfilled.supaset + item.fulfilled.watershield,
      'cement target': item.target,
    }));
    return csvData;
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-12 gap-3">
      <div className="flex-col col-span-5">
        <Header
          name="Current Period"
          dateRange={`(${startDate} - ${endDate})`}
          headerData={headerDataCurrentPeriod}
        />
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataCurrentPeriod)}
            filename={`cementChartData_${startDate}_to_${endDate}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>
        <div>
          <ResponsiveContainer height={50 + chartDataCurrentPeriod.length * 50}>
            <BarChart
              data={chartDataCurrentPeriod}
              layout="vertical"
              margin={{
                top: 5,
                right: 50,
                left: 50,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" fontSize={12} />
              <Tooltip />
              <Legend />

              <Bar dataKey="fulfilled.classic" name="classic" stackId="a" fill="#1D4370" />
              <Bar dataKey="fulfilled.supaset" name="supaset" stackId="a" fill="#4A698D" />
              <Bar dataKey="fulfilled.watershield" name="watershield" stackId="a" fill="#6D8AA1">
                <LabelList
                  dataKey="fulfilled.total"
                  position="right"
                  formatter={renderLabelRoundedOnTwoDecimalPlaces}
                  fontSize="12"
                />
              </Bar>
              <Bar dataKey="target" name="target" fill="#7CB342">
                <LabelList fontSize="12" dataKey="target" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex-col text-center col-span-2">
        <h2 className="font-outfit font-medium text-xl">SPLP</h2>
        {performance.map((item: PerformanceData) => (
          <div className="p-5 shadow rounded-md text-sm" key={item.name}>
            <b>{item.name}</b>
            <br />
            <span
              className={clsx(
                item.value > 0 && 'text-green-700',
                item.value === 0 && 'text-secondary',
                item.value < 0 && 'text-red-700'
              )}
            >
              {item.value}%
            </span>
          </div>
        ))}
      </div>
      <div className="flex-col col-span-5">
        <Header
          name="Last Period"
          dateRange={`(${startDateLastPeriod} - ${endDateLastPeriod})`}
          headerData={headerDataPreviousPeriod}
        />
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataPreviousPeriod)}
            filename={`cementChartData_${startDateLastPeriod}_to_${endDateLastPeriod}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={50 + chartDataPreviousPeriod.length * 50}>
            <BarChart
              data={chartDataPreviousPeriod}
              layout="vertical"
              margin={{
                top: 5,
                right: 50,
                left: 50,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" fontSize={12} />
              <Tooltip />
              <Legend />

              <Bar dataKey="fulfilled.classic" name="classic" stackId="a" fill="#1D4370" />
              <Bar dataKey="fulfilled.supaset" name="supaset" stackId="a" fill="#4A698D" />
              <Bar dataKey="fulfilled.watershield" name="watershield" stackId="a" fill="#6D8AA1">
                <LabelList
                  dataKey="fulfilled.total"
                  position="right"
                  formatter={renderLabelRoundedOnTwoDecimalPlaces}
                  fontSize="12"
                />
              </Bar>
              <Bar dataKey="target" name="target" fill="#7CB342" fontSize={12}>
                <LabelList fontSize="12" dataKey="target" position="right" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CementChart;
