import { useState } from 'react';
import SearchBar from './ui/blocks/SearchBar';
import LefCenter from './ui/partials/LeftCenter';
import Right from './ui/partials/Right';
import { SiteStatus } from '../../common/hooks/useSites';

const Sites: React.FC = () => {
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [siteStatus, setSiteStatus] = useState<SiteStatus | null>(null);

  const onSearchChange = (newSearchText: string) => {
    setSearchText(newSearchText);

    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const newSearchTextAlphanumeric = newSearchText
      .split('')
      .filter(char => chars.includes(char))
      .join('');

    if (newSearchTextAlphanumeric.length === 20) {
      setSelectedSiteId(newSearchTextAlphanumeric);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SearchBar onSearchChange={onSearchChange} onStatusChange={setSiteStatus} />
      <div className="flex flex-grow overflow-hidden">
        <LefCenter
          searchText={searchText}
          siteStatus={siteStatus}
          setSelectedSiteId={setSelectedSiteId}
        />
        {selectedSiteId && (
          <Right selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} />
        )}
      </div>
    </div>
  );
};

export default Sites;
