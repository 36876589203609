/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteField } from 'firebase/firestore';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import CloseButton from '../../../../components/CloseButton';
import Cluster from '../components/Cluster';
import useOutlet from '../../../../common/hooks/useOutlet';
import updateOutlet from '../../../../common/db/outlets';
import ConfirmDeactivation from '../components/ConfirmDeactivation';
import OutletStatusIndicator from '../components/OutletStatusIndicator';
import RejectDeactivation from '../components/RejectDeactivation';

interface OutletDetailsProps {
  selectedOutletId: string;
  setSelectedOutletId: (id: string | undefined) => void;
}

const OutletDetails: React.FC<OutletDetailsProps> = ({ selectedOutletId, setSelectedOutletId }) => {
  const { error, loading, outlet } = useOutlet(selectedOutletId);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showRejection, setShowRejection] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const handleOnClose = () => setSelectedOutletId(undefined);

  const handleOnDeactivationConfirm = () => {
    setShowConfirmation(true);
  };

  const handleOnDeactivationReject = () => {
    setShowRejection(true);
  };

  const onDeactivationConfirm = () => {
    updateOutlet(selectedOutletId, { active: false });
    handleOnClose();
  };

  const onDeactivationReject = (comment: string) => {
    updateOutlet(selectedOutletId, {
      deletionRequestRejectionComment: comment,
      deletionRequested: deleteField(),
    });
    handleOnClose();
  };

  return (
    <>
      <ConfirmDeactivation
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={onDeactivationConfirm}
      />
      <RejectDeactivation
        open={showRejection}
        setOpen={setShowRejection}
        onConfirm={onDeactivationReject}
      />
      <Card className="w-1/2 md:w-full my-4 p-4 flex flex-col overflow-y-auto no-scrollbar">
        <div className="flex justify-between items-center mb-4">
          <OutletStatusIndicator
            className="ml-4"
            active={outlet?.active}
            deletionRequested={outlet?.deletionRequested}
          />
          <CloseButton onClick={handleOnClose} className="max-w-min self-end" />
        </div>
        {!outlet && !loading && !error ? (
          <div className="text-center flex justify-between bg-acc">
            Select a outlet to view details
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex">
              {outlet?.active && outlet?.deletionRequested && (
                <div className="w-full">
                  <h1 className="font-bold ml-4">Deactivation requested</h1>
                  <div className="flex">
                    <Button
                      type="button"
                      color="primary"
                      onClick={handleOnDeactivationReject}
                      className="text-center grow"
                    >
                      Reject
                    </Button>
                    <Button
                      type="button"
                      color="accent2"
                      onClick={handleOnDeactivationConfirm}
                      className="text-center grow"
                    >
                      Deactivate
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <h1 className="font-thin font-nunito">{outlet?.id}</h1>
            <h1 className="font-extrabold font-nunito text-2xl">{outlet?.name}</h1>
            <hr />
            <div>
              <h2 className="font-bold mt-3">Retailer</h2>
              <p>
                {outlet?.retailer.name}{' '}
                <span className="font-thin text-sm">({outlet?.retailer?.id})</span>
              </p>
              <p>HasWhatsApp: {outlet?.retailer.hasWhatsApp ? 'true' : 'false'}</p>

              <h2 className="font-bold mt-3">Primary FSA</h2>
              <p>
                {outlet?.primaryFSA.name}{' '}
                <span className="font-thin text-sm">({outlet?.primaryFSA?.id})</span>
              </p>

              <h2 className="font-bold mt-3">Primary Distributor</h2>
              <p>
                {outlet?.primaryDistributor.name}{' '}
                <span className="font-thin text-sm">({outlet?.primaryDistributor?.id})</span>{' '}
              </p>

              <h2 className="font-bold mt-3">SOP</h2>
              <p>
                {outlet?.sop.name} <span className="font-thin text-sm">({outlet?.sop?.id})</span>
              </p>

              <h2 className="font-bold mt-3">FSM</h2>
              <p>
                {outlet?.fsm?.name} <span className="font-thin text-sm">({outlet?.fsm?.id})</span>
              </p>
              <p>HasWhatsApp: {outlet?.fsm?.hasWhatsApp ? 'true' : 'false'}</p>
              <h2 className="font-bold mt-3">User IDs</h2>
              <ul className="list-inside list-disc">
                {outlet?.userIds.map(userId => (
                  <li key={userId}>{userId}</li>
                ))}

                <h2 className="font-bold mt-3">Other</h2>
                <p>Status: {outlet?.status}</p>
                {outlet?.cluster && <Cluster cluster={outlet.cluster} />}
              </ul>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default OutletDetails;
