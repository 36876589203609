import SiteDetails from '../blocks/SiteDetails';

interface RightProps {
  selectedSiteId: string;
  setSelectedSiteId: (id: string | undefined) => void;
}

const Right: React.FC<RightProps> = ({ selectedSiteId, setSelectedSiteId }) => (
  <SiteDetails selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} />
);

export default Right;
