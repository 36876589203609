import { ProtectedRoute } from '../../components/Routing';
import MissingDeliveries from './MissingDeliveries';

const MissingDeliveriesRoute = {
  path: 'deliveries',
  element: (
    <ProtectedRoute roles={['admin']}>
      <MissingDeliveries />
    </ProtectedRoute>
  ),
};

export default MissingDeliveriesRoute;
