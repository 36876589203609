import clsx from 'clsx';
import { useMemo } from 'react';

interface OutletStatusIndicatorProps {
  active?: boolean;
  deletionRequested?: boolean;
  className?: string;
}

const OutletStatusIndicator: React.FC<OutletStatusIndicatorProps> = ({
  active,
  deletionRequested,
  className,
}) => {
  const status = useMemo(() => {
    if (active && deletionRequested) {
      return 'Deletion Requested';
    }
    return active ? 'Active' : 'Inactive';
  }, [active, deletionRequested]);

  const color = useMemo(() => {
    if (active && deletionRequested) {
      return 'accent4';
    }
    return active ? 'accent5' : 'accent2';
  }, [active, deletionRequested]);

  const style = clsx(`flex w-3 h-3 rounded-full bg-${color}`, className);

  return <span className={style} title={status} />;
};

export default OutletStatusIndicator;
