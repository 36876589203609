import { useState } from 'react';
import SearchBar from './ui/blocks/SearchBar';
import { MissingDeliveryStatus } from '../../common/types/MissingDelivery';
import LeftCenter from './ui/partials/LeftCenter';
import Right from './ui/partials/Right';
import Header from '../../components/Header';

const MissingDeliveries: React.FC = () => {
  const [searchText, setSearchText] = useState<string>();
  const [status, setStatus] = useState<MissingDeliveryStatus>();
  const [selectedMissingDeliveryId, setSelectedMissingDeliveryId] = useState<string>();

  const onSearchChange = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  const onStatusChange = (newStatus: MissingDeliveryStatus) => {
    setStatus(newStatus);
  };

  return (
    <>
      <Header title="Missing Deliveries" />
      <SearchBar onSearchChange={onSearchChange} onStatusChange={onStatusChange} />
      <div className="flex w-full h-full">
        <LeftCenter
          searchText={searchText}
          status={status}
          setMissingDeliveryId={setSelectedMissingDeliveryId}
          selectedMissingDeliveryId={selectedMissingDeliveryId}
        />
        <Right
          selectedMissingDeliveryId={selectedMissingDeliveryId}
          setSelectedMissingDeliveryId={setSelectedMissingDeliveryId}
        />
      </div>
    </>
  );
};

export default MissingDeliveries;
