import { useMemo } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend, Cell } from 'recharts';
import CsvDownloader from 'react-csv-downloader';
import useStore from '../../common/hooks/useStore';
import { AnalyticsType, FilterOptions } from '../../common/types/Analytics';
import csvLogo from '../../common/assets/images/export-csv.png';
import computeChannels from '../../common/utils/analytics/computations/computeChannel';
import Header from './Header';

interface OutletsChartProps {
  data: AnalyticsType[];
  filter: FilterOptions;
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div className="bg-white shadow p-2">
        <p className="text-black">{`${payload[0].payload.channel ?? 'not_defined'}`}</p>
        <p className="text-blue-900">{`Outlets: ${payload[0].payload.numberOfOutlets}`}</p>
        {/* <p className="text-red-800">{`Volume: ${payload[0].payload.volume}`}</p> */}
      </div>
    );
  }

  return null;
};

const ChannelChart: React.FC<OutletsChartProps> = ({ data, filter }) => {
  const [state] = useStore();

  const [chartDataCurrentPeriod, chartDataPreviousPeriod]: any = useMemo(
    () => computeChannels(data, filter),
    [state.recompute]
  );

  const startDate = filter.dateRange1.startDate.toLocaleDateString();
  const endDate = filter.dateRange1.endDate.toLocaleDateString();
  const startDateLastPeriod = filter.dateRange2.startDate.toLocaleDateString();
  const endDateLastPeriod = filter.dateRange2.endDate.toLocaleDateString();

  const downloadCSV = (dataToDownload: any) => {
    const csvData = dataToDownload.map((item: any) => ({
      channel: item.channel,
      numberOfOutlets: item.numberOfOutlets,
      // volume: item.volume,
    }));
    return csvData;
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-12 gap-3">
      <div className="flex-col col-span-6">
        <Header
          name="Current Period"
          dateRange={`(${startDate} - ${endDate})`}
          headerData={chartDataCurrentPeriod}
        />
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataCurrentPeriod)}
            filename={`channelsChartData_${startDate}_to_${endDate}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>

        <ResponsiveContainer
          width="100%"
          height={chartDataCurrentPeriod.length > 1 ? 50 + chartDataCurrentPeriod.length * 35 : 400}
        >
          {/* <div className="text-center mb-1"> */}
          <PieChart>
            <Pie
              data={chartDataCurrentPeriod}
              dataKey="numberOfOutlets"
              nameKey="channel"
              cx="50%"
              cy="50%"
              outerRadius={90}
              label
              labelLine={false}
              legendType="square"
            >
              {chartDataCurrentPeriod.map((entry: any, index: number) => (
                <Cell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cell-${index}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
            <Legend align="right" layout="vertical" name="" />
            <Tooltip content={CustomTooltip} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="flex-col col-span-6">
        <Header
          name="Last Period"
          dateRange={`(${startDateLastPeriod} - ${endDateLastPeriod})`}
          headerData={chartDataPreviousPeriod}
        />
        <div className="flex justify-end mb-1">
          <CsvDownloader
            datas={downloadCSV(chartDataPreviousPeriod)}
            filename={`channelsChartData_${startDateLastPeriod}_to_${endDateLastPeriod}`}
            extension=".csv"
            separator=","
          >
            <img src={csvLogo} alt="csvDownload" width="30" />
          </CsvDownloader>
        </div>

        <div className="text-center mb-1">
          <ResponsiveContainer
            width="100%"
            height={
              chartDataCurrentPeriod.length > 1 ? 50 + chartDataCurrentPeriod.length * 35 : 400
            }
          >
            <PieChart>
              <Pie
                data={chartDataPreviousPeriod}
                dataKey="numberOfOutlets"
                nameKey="channel"
                cx="50%"
                cy="50%"
                outerRadius={90}
                label
                labelLine={false}
              >
                {chartDataCurrentPeriod.map((entry: any, index: number) => (
                  <Cell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`cell-${index}`}
                    fill={entry.color}
                  />
                ))}
              </Pie>
              <Legend align="right" layout="vertical" />
              <Tooltip content={CustomTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ChannelChart;
