import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../utils/firebase';

export const confirmMissingDelivery = async (id: string) => {
  const ref = doc(firestore, 'missingDeliveries', id);
  await updateDoc(ref, { status: 'confirmed' });
};

export const rejectMissingDelivery = async (id: string) => {
  const ref = doc(firestore, 'missingDeliveries', id);
  await updateDoc(ref, { status: 'rejected' });
};
