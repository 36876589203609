/* eslint-disable no-nested-ternary */
import { OutletTypeTypesense } from '../../../../common/types';
import Card from '../../../../components/Card';
import OutletStatusIndicator from './OutletStatusIndicator';

interface OutletItemProps {
  outlet: OutletTypeTypesense;
  onClick: (id: string) => void;
}

const OutletItem: React.FC<OutletItemProps> = ({ outlet, onClick }) => (
  <Card
    onClick={() => onClick(outlet.id)}
    className="md:w-full border-none shadow-none flex justify-between flex-wrap hover:bg-accent1 hover:text-primary hover:cursor-pointer"
  >
    <h1 className="flex-1/2 font-bold font-outfit text-2xl">{outlet.name}</h1>
    <h2 className="flex-1/2 text-right font-medium text-lg">{outlet['retailer.name']}</h2>
    <p className="flex-1/2 font-thin">Cluster: {outlet['cluster.name']}</p>
    <OutletStatusIndicator active={outlet.active} deletionRequested={outlet.deletionRequested} />
    <p className="flex-1/2 font-thin">Channel: {outlet.channel ? outlet.channel : '-'}</p>
  </Card>
);

export default OutletItem;
