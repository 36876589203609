import OutletDetails from '../blocks/OutletDetails';

interface RightProps {
  selectedOutletId: string;
  setSelectedOutletId: (id: string | undefined) => void;
}

const Right: React.FC<RightProps> = ({ selectedOutletId, setSelectedOutletId }) => (
  <OutletDetails selectedOutletId={selectedOutletId} setSelectedOutletId={setSelectedOutletId} />
);

export default Right;
