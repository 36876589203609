import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

interface ConfirmDeactivationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmDeactivation: React.FC<ConfirmDeactivationProps> = ({
  open,
  setOpen,
  onConfirm,
  onCancel,
}) => {
  const handleOnConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleOnCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <h1 className="text-2xl font-bold">Confirm Deactivation</h1>
      <p>Are you sure you want to deactivate the site?</p>
      <div className="flex justify-between mt-6">
        <Button onClick={handleOnConfirm} color="accent5" className="grow">
          Confirm
        </Button>
        <Button onClick={handleOnCancel} color="accent2" className="grow">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeactivation;
