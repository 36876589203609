import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth } from '../../common/hooks/auth';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Filter from '../../components/Analytics/Filter';
import 'react-tabs/style/react-tabs.css';
import useStore from '../../common/hooks/useStore';
import FSACharts from '../../components/Charts/FSACharts';
import TAECharts from '../../components/Charts/TAECharts';

interface DashboardProps {}
const Dashboard: React.FC<DashboardProps> = () => {
  const { user } = useAuth();
  const [state, dispatch] = useStore();

  return (
    <div className="w-full overflow-y-auto overflow-x-hidden p-8">
      {user && (
        <>
          <h1 className="font-outfit font-medium text-2xl my-5">REAL - Secondary Sales</h1>
          <Filter />
          <Tabs
            selectedIndex={state.selectedTabIndex}
            onSelect={index => dispatch('SET_SELECTED_TAB_INDEX', index)}
            className="bg-primary mt-5"
          >
            <TabList className="flex font text-center font-outfit font-medium mb-5">
              <Tab className="cursor-pointer w-6/12 p-3 shadow">FSA</Tab>
              <Tab className="cursor-pointer w-6/12 p-3 shadow">TAE</Tab>
            </TabList>
            <TabPanel>
              <FSACharts />
            </TabPanel>
            <TabPanel>
              <TAECharts />
            </TabPanel>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default Dashboard;
