import { SiteStatus } from '../../../../common/hooks/useSites';
import SitesList from '../blocks/SitesList';

interface LeftCenterProps {
  searchText?: string;
  setSelectedSiteId: (id: string) => void;
  siteStatus: SiteStatus | null;
}

const LefCenter: React.FC<LeftCenterProps> = ({ siteStatus, searchText, setSelectedSiteId }) => (
  <SitesList searchText={searchText} status={siteStatus} setSelectedSiteId={setSelectedSiteId} />
);

export default LefCenter;
