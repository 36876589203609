interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => (
  <header className="w-full border-b border-gray-200">
    <div className="py-6 px-4">
      <h1 className=" font-xl font-bold text-gray-900">{title}</h1>
    </div>
  </header>
);
export default Header;
