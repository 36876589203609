import { useEffect, useMemo } from 'react';
import { doc, FirestoreError, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { MissingDelivery } from '../types/MissingDelivery';
import { useLoadingValue } from './utils';
import { docToJSON, firestore } from '../utils/firebase';
import { confirmMissingDelivery, rejectMissingDelivery } from '../db/missingDelivery';

const useMissingDelivery = (id?: string) => {
  const { error, loading, setLoading, setError, setValue, value } = useLoadingValue<
    MissingDelivery | null,
    FirestoreError
  >();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    if (id) {
      const ref = doc(firestore, 'missingDeliveries', id);
      unsubscribe = onSnapshot(
        ref,
        snapshot => {
          setValue(docToJSON(snapshot));
          setLoading(false);
        },
        setError
      );
    } else {
      setValue(null);
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [id]);

  const confirm = async () => {
    if (!id) return;
    try {
      setLoading(true);
      await confirmMissingDelivery(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof FirestoreError) setError(err);
    }
  };

  const reject = async () => {
    if (!id) return;
    try {
      setLoading(true);
      await rejectMissingDelivery(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof FirestoreError) setError(err);
    }
  };

  return useMemo(
    () => ({
      missingDelivery: value,
      error,
      loading,
      confirm,
      reject,
    }),
    [value, loading, error]
  );
};

export default useMissingDelivery;
