import clsx from 'clsx';
import MissingDeliveryDetails from '../blocks/MissingDeliveryDetails';

interface RightProps {
  selectedMissingDeliveryId: string | undefined;
  setSelectedMissingDeliveryId: (id: string | undefined) => void;
  className?: string;
}

const Right: React.FC<RightProps> = ({
  selectedMissingDeliveryId,
  setSelectedMissingDeliveryId,
  className,
}) => {
  const style = clsx(
    className,
    'flex flex-col items-center w-1/2 px-10 py-8',
    !selectedMissingDeliveryId && 'hidden'
  );

  return (
    <div className={style}>
      <MissingDeliveryDetails
        selectedMissingDeliveryId={selectedMissingDeliveryId}
        setSelectedMissingDeliveryId={setSelectedMissingDeliveryId}
      />
    </div>
  );
};

export default Right;
