import { useMemo } from 'react';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { useAuth } from './auth';
import { usePaginationTypesense } from './utils/usePaginationTypesense';
import { MissingDeliveryTypeTypesense } from '../types/MissingDelivery';

export type MissingDeliveryStatus = 'pending' | 'confirmed' | 'rejected' | 'exists';

interface UseMissingDeliveriesOptions {
  searchText?: string;
  pageSize?: number;
  status?: MissingDeliveryStatus | null;
  emptySearch?: boolean;
}

const useMissingDeliveries = ({
  searchText,
  pageSize = 10,
  status,
  emptySearch = false,
}: UseMissingDeliveriesOptions) => {
  const { typesenseKey } = useAuth();

  const query: SearchParams | undefined = useMemo(() => {
    if (!emptySearch && !searchText) return undefined;

    const q = {
      q: searchText ?? '',
      query_by: 'distributor.name',
      filter_by: '',
    };

    if (status === 'pending') q.filter_by = 'status: [pending]';

    if (status === 'confirmed') q.filter_by = 'status: [confirmed]';

    if (status === 'rejected') q.filter_by = 'status: [rejected]';

    if (status === 'exists') q.filter_by = 'status: [exists]';

    return q;
  }, [searchText, status, emptySearch]);

  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense<MissingDeliveryTypeTypesense>(
      query,
      pageSize,
      'missingDeliveries',
      typesenseKey
    );

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      missingDeliveries: values ? values.map(v => ({ ...v.document, id: v.document.id })) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useMissingDeliveries;
