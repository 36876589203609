import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

interface ConfirmDeactivationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmDeactivation: React.FC<ConfirmDeactivationProps> = ({
  open,
  setOpen,
  onConfirm,
  onCancel,
}) => {
  const handleOnConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    setOpen(false);
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <h1 className="text-2xl font-bold">Confirm Deactivation</h1>
      <p>Are you sure you want to deactivate the outlet?</p>
      <div className="flex justify-between mt-6">
        <Button onClick={handleOnCancel} color="primary" className="w-1/2">
          Cancel
        </Button>
        <Button onClick={handleOnConfirm} color="accent2" className="w-1/2">
          Deactivate
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeactivation;
