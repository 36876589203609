/* eslint-disable react/jsx-props-no-spreading */
import { SubmitHandler, useForm, RegisterOptions } from 'react-hook-form';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

interface RejectDeactivationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm?: (comment: string) => void;
  onCancel?: () => void;
}

type RejectDeactivationForm = {
  comment: string;
};

const RejectDeactivation: React.FC<RejectDeactivationProps> = ({ open, setOpen, onConfirm }) => {
  const { register, handleSubmit } = useForm<RejectDeactivationForm>();

  const commentOptions: RegisterOptions = {
    required: 'Comment is required',
    maxLength: {
      value: 300,
      message: 'Comment should not exceed 255 characters',
    },
  };

  const handleOnConfirm: SubmitHandler<RejectDeactivationForm> = data => {
    if (onConfirm) {
      onConfirm(data.comment);
    }
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <h1 className="text-2xl font-bold">Reject Deactivation</h1>
      <p>Are you sure you want to reject the deactivation request?</p>
      <form onSubmit={handleSubmit(handleOnConfirm)}>
        <textarea
          {...register('comment', commentOptions)}
          className="w-full h-32 mt-4 p-2 border border-gray-300 rounded"
          placeholder="Enter your comment here"
        />
        <div className="flex justify-between mt-6">
          <Button type="submit" color="primary" className="grow">
            Reject Deactivation
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RejectDeactivation;
