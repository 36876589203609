import { useState } from 'react';
import SearchBar from './ui/blocks/SearchBar';
import LefCenter from './ui/partials/LeftCenter';
import Right from './ui/partials/Right';
import { OutletStatus } from '../../common/hooks/useOutlets';

const Outlets: React.FC = () => {
  const [selectedOutletId, setSelectedOutletId] = useState<string>();
  const [searchText, setSearchText] = useState<string>();
  const [outletStatus, setOutletStatus] = useState<OutletStatus | null>(null);

  const onSearchChange = (newSearchText: string) => {
    setSearchText(newSearchText);

    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const newSearchTextAlphanumeric = newSearchText
      .split('')
      .filter(char => chars.includes(char))
      .join('');

    if (newSearchTextAlphanumeric.length === 20) {
      setSelectedOutletId(newSearchTextAlphanumeric);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SearchBar onSearchChange={onSearchChange} onStatusChange={setOutletStatus} />
      <div className="flex flex-grow overflow-hidden">
        <LefCenter
          searchText={searchText}
          outletStatus={outletStatus}
          setSelectedOutletId={setSelectedOutletId}
        />
        {selectedOutletId && (
          <Right selectedOutletId={selectedOutletId} setSelectedOutletId={setSelectedOutletId} />
        )}
      </div>
    </div>
  );
};

export default Outlets;
