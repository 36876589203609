import dayjs from 'dayjs';
import clsx from 'clsx';
import { MissingDeliveryTypeTypesense } from '../../../../common/types/MissingDelivery';

interface MissingDeliveryItemProps {
  missingDelivery: MissingDeliveryTypeTypesense;
  onClick: (id: string) => void;
  selected?: boolean;
}

const MissingDeliveryItem: React.FC<MissingDeliveryItemProps> = ({
  missingDelivery,
  onClick,
  selected = false,
}) => {
  const style = clsx('w-full md:w-full border hover:bg-slate-100 p-4 flex justify-between', {
    'bg-slate-100': selected,
    'bg-white': !selected,
  });

  return (
    <button type="button" onClick={() => onClick(missingDelivery.id)} className={style}>
      <div className="flex flex-col items-start">
        <h1>
          LOGON: <span className="font-bold">{missingDelivery.LOGONOrderNumber}</span>
        </h1>
        <h2>{dayjs(missingDelivery.deliveryDate * 1000).format('YYYY-MM-DD')}</h2>
      </div>
      <div className="flex flex-col items-start">
        <p>
          <span className="font-bold">{missingDelivery.quantity}</span> tons of{' '}
          <span className="font-bold">{missingDelivery.productNameInternal}</span>
        </p>
        <p className="text-sm">({missingDelivery.productName})</p>
      </div>
      <div className="flex flex-col items-end">
        <h1>{missingDelivery.distributor?.name}</h1>
        <h2>{missingDelivery.distributor?.sapId}</h2>
      </div>
    </button>
  );
};

export default MissingDeliveryItem;
