import { useMemo } from 'react';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { useAuth } from './auth';
import { usePaginationTypesense } from './utils/usePaginationTypesense';
import { SiteTypeTypesense } from '../types/Site';

export type SiteStatus = 'active' | 'inactive' | 'markedForDeletion';
interface UseSitesOptions {
  searchText?: string;
  pageSize?: number;
  status?: SiteStatus | null;
  emptySearch?: boolean;
}

const useSites = ({ searchText, pageSize = 10, status, emptySearch = false }: UseSitesOptions) => {
  const { typesenseKey } = useAuth();

  const sitesQuery: SearchParams | undefined = useMemo(() => {
    if (!emptySearch && !searchText) return undefined;

    const query = {
      q: searchText ?? '',
      query_by:
        'name, siteOwner.id, siteOwner.name, siteOwner.phone, primaryTAE.id, primaryTAE.name, cluster.name',
      filter_by: '',
    };

    if (status === 'active') query.filter_by = 'active: [true]';

    if (status === 'inactive') query.filter_by = 'active: [false]';

    if (status === 'markedForDeletion')
      query.filter_by = 'deletionRequested: [true] && active: [true]';

    return query;
  }, [searchText, status]);

  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense<SiteTypeTypesense>(sitesQuery, pageSize, 'sites', typesenseKey);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      sites: values ? values.map(v => ({ ...v.document, id: v.document.id })) : [],
    }),
    [error, hasMore, loading, values]
  );
};

export default useSites;
