import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import DeniedRoute from './pages/Denied';
import EnterRoute from './pages/Enter';
import HomeRoute from './pages/Home';
import NotFoundRoute from './pages/NotFound';
import TargetsRoute from './pages/Targets';
import UsersRoute from './pages/Users';
import OutletsRoute from './pages/Outlets';
import DashboardRoute from './pages/Dashboard';
import ErrorElement from './components/ErrorElement';
import SitesRoute from './pages/Sites';
import CostsOverviewRoute from './pages/CostsOverview';
import GroupsRoute from './pages/Groups';
import MissingDeliveriesRoute from './pages/MissingDeliveries';

const IndexRoute = {
  index: true,
  element: <Navigate to="home" />,
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorElement />,
    children: [
      IndexRoute,
      HomeRoute,
      EnterRoute,
      UsersRoute,
      OutletsRoute,
      SitesRoute,
      TargetsRoute,
      GroupsRoute,
      DashboardRoute,
      CostsOverviewRoute,
      DeniedRoute,
      NotFoundRoute,
      MissingDeliveriesRoute,
    ],
  },
]);

export default router;
