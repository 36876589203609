import { SiteTypeTypesense } from '../../../../common/types/Site';
import Card from '../../../../components/Card';
import SiteStatusIndicator from './SiteStatusIndicator';

interface SiteItemProps {
  site: SiteTypeTypesense;
  onClick: (id: string) => void;
}

const SiteItem: React.FC<SiteItemProps> = ({ site, onClick }) => (
  <Card
    onClick={() => onClick(site.id)}
    className="md:w-full border-none shadow-none flex justify-between flex-wrap hover:bg-accent1 hover:text-primary hover:cursor-pointer"
  >
    <h1 className="flex-1/2 font-bold font-outfit text-2xl">{site.name}</h1>
    <h2 className="flex-1/2 text-right font-medium text-lg">{site['siteOwner.name']}</h2>
    <p className="flex-1/2 font-thin">Cluster: {site['cluster.name']}</p>
    <SiteStatusIndicator active={site.active} deletionRequested={site.deletionRequested} />
    <p className="flex-1/2 font-thin">Type: {site.type ? site.type : '-'}</p>
  </Card>
);

export default SiteItem;
