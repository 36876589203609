import { AnalyticsType } from '../../common/types/Analytics';
import CementChart from './CementChart';
import MortarChart from './MortarChart';
import ChatbotAdoptionChart from './ChatbotAdoptionChart';
import OutletsChart from './OutletsChart';
import WhatsAppEnabledChart from './WhatsappEnabledChart';
import VisitsChart from './VisitsChart';
import SalesSplitChart from './SalesSplitChart';
import AverageSellingPriceChart from './AverageSellingPriceChart';
import AverageBuyingPriceChart from './AverageBuyingPriceChart';
import useStore from '../../common/hooks/useStore';
import loadingLogo from '../../common/assets/images/loadingLogo.png';
import { useAnalytics } from '../../common/hooks';
import ChannelChart from './ChannelChart';

interface FSAChartsProps {}

const FSACharts: React.FC<FSAChartsProps> = () => {
  const [state] = useStore();
  const { data, error, loading } = useAnalytics<AnalyticsType>({
    type: 'fsa',
    dateRange1: state.filter.dateRange1,
    dateRange2: state.filter.dateRange2,
    groupBy: state.filter.groupBy,
    regions: state.filter.regions,
    territories: state.filter.territories,
    clusters: state.filter.clusters,
  });

  if (data && data?.length > 0 && !loading) {
    return (
      <>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl mb-5 text-center">Cement Sales (tons)</h1>
          <CementChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">Mortar Sales (tons)</h1>
          <MortarChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">
            Chatbot Adoption (tons)
          </h1>
          <ChatbotAdoptionChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">Outlets</h1>
          <OutletsChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">Outlets by Channel</h1>
          <ChannelChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">WhatsApp Enabled</h1>
          <WhatsAppEnabledChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">Visits</h1>
          <VisitsChart data={data} filter={state.filter} />
        </div>
        {state.filter.groupBy.toLowerCase() !== 'fst' && (
          <div className="shadow bg-white p-5 mb-5">
            <h1 className="font-outfit font-medium text-2xl my-5 text-center">
              Sales Split (tons)
            </h1>
            <SalesSplitChart data={data} filter={state.filter} />
          </div>
        )}
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">
            Average Buying Price
          </h1>
          <AverageBuyingPriceChart data={data} filter={state.filter} />
        </div>
        <div className="shadow bg-white p-5 mb-5">
          <h1 className="font-outfit font-medium text-2xl my-5 text-center">
            Average Selling Price
          </h1>
          <AverageSellingPriceChart data={data} filter={state.filter} />
        </div>
      </>
    );
  }
  if (loading) {
    return (
      <img src={loadingLogo} alt="loading logo" className="m-auto h-10 w-10 animate-pulse mt-10" />
    );
  }
  if (error) {
    return (
      <div className="m-auto">
        <p className="mt-10 text-center">{error}</p>
      </div>
    );
  }
  return (
    <div className="m-auto">
      <p className="mt-10 text-center">No data found for the selected date range</p>
    </div>
  );
};

export default FSACharts;
