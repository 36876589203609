/* eslint-disable react/button-has-type */
import clsx from 'clsx';
import React from 'react';

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?: 'primary' | 'secondary' | 'accent1' | 'accent2' | 'accent3' | 'accent4' | 'accent5';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  text?: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  color = 'primary',
  disabled = false,
  text,
  type = 'button',
  className,
}) => {
  const style = clsx(
    'text-center border rounded-lg cursor-pointer font-bold py-2 px-6',
    color === 'primary' &&
      'bg-primary text-secondary border-secondary hover:bg-secondary hover:text-primary',
    color === 'secondary' &&
      'bg-secondary text-primary hover:bg-primary hover:text-secondary hover:border-secondary',
    color === 'accent1' &&
      ' bg-accent1 text-primary hover:bg-primary hover:text-accent1 hover:border-accent1',
    color === 'accent2' &&
      'bg-accent2 text-white hover:bg-primary hover:text-accent2 hover:border-accent2',
    color === 'accent3' &&
      'bg-accent3 text-white hover:bg-primary hover:text-accent3 hover:border-accent3',
    color === 'accent4' &&
      'bg-accent4 text-white hover:bg-primary hover:text-accent4 hover:border-accent4',
    color === 'accent5' &&
      'bg-accent5 text-white hover:bg-primary hover:text-accent5 hover:border-accent5',
    className
  );

  return (
    <button
      type={type}
      className={style}
      onClick={onClick}
      disabled={disabled}
      data-testid="button"
    >
      {children}
      {text}
    </button>
  );
};

export default Button;
