import dayjs from 'dayjs';
import { useState } from 'react';
import MonthSelector from './ui/blocks/MonthSelector';
import TargetsList from './ui/blocks/TargetsList';

const Targets: React.FC = () => {
  const [month, setMonth] = useState<number>(dayjs().month() + 1);
  const [year, setYear] = useState<number>(dayjs().year());

  const handleDateChange = (date: dayjs.Dayjs) => {
    setMonth(date.month() + 1);
    setYear(date.year());
  };

  return (
    <div className="w-full h-full flex justify-center p-8">
      <MonthSelector onDateChange={handleDateChange} />
      <TargetsList month={month} year={year} targetTypes={['cluster']} />
    </div>
  );
};

export default Targets;
