import { useMemo } from 'react';

interface SiteStatusIndicatorProps {
  active?: boolean;
  deletionRequested?: boolean;
}

const SiteStatusIndicator: React.FC<SiteStatusIndicatorProps> = ({ active, deletionRequested }) => {
  const status = useMemo(() => {
    if (active && deletionRequested) {
      return 'Deletion Requested';
    }
    return active ? 'Active' : 'Inactive';
  }, [active, deletionRequested]);

  const color = useMemo(() => {
    if (active && deletionRequested) {
      return 'accent4';
    }
    return active ? 'accent5' : 'accent2';
  }, [active, deletionRequested]);

  return <span className={`flex w-3 h-3 rounded-full bg-${color}`} title={status} />;
};

export default SiteStatusIndicator;
