import { OutletStatus } from '../../../../common/hooks/useOutlets';
import OutletsList from '../blocks/OutletsList';

interface LeftCenterProps {
  searchText?: string;
  setSelectedOutletId: (id: string) => void;
  outletStatus: OutletStatus | null;
}

const LefCenter: React.FC<LeftCenterProps> = ({
  searchText,
  setSelectedOutletId,
  outletStatus,
}) => (
  <OutletsList
    searchText={searchText}
    setSelectedOutletId={setSelectedOutletId}
    status={outletStatus}
  />
);

export default LefCenter;
