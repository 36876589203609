interface ContentProps {
  children: React.ReactNode;
}

const Content: React.FC<ContentProps> = ({ children }) => (
  <div data-testid="content" className="flex flex-col items-center w-full">
    {children}
  </div>
);

export default Content;
